import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ContentCard from 'components/molecules/ContentCard';
import { Link } from 'react-router-dom';
import EventCard from 'components/molecules/EventCard';
import { all_click_datalayer_push, getContentsGAProps, getEventGAProps, getGAClickProps } from 'utils/GAUtils';
import { useMyInfo } from 'hooks';
import { imageUrl } from 'utils/utils';
import paths from '../../../../../commons/paths';
import { MESSAGE_EVENT_IDS } from '../../../Event/constants';
import { GET_LOADED_ENVIRONMENT_TYPE } from '../../../../../commons/constants';
import { getCommentContentsByReplacedMentionTagToGoalBangYee } from '../../../../../utils/mentionUtils';

interface MainRecommendProps extends MainRecommendListResDto {
  currentDt: string;
}

const MainRecommend = (props: MainRecommendProps) => {
  const { info } = useMyInfo();
  const [userInfo, setUserInfo] = useState<UserDetailInfoResDto>();
  const [screenSize, setScreenSize] = useState('lg');

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1023) setScreenSize('lg');
    else if (width > 767) setScreenSize('md');
    else setScreenSize('sm');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (info) setUserInfo(info);
  }, [info]);

  const messageEventIds = MESSAGE_EVENT_IDS.find(data => data.environmentType === GET_LOADED_ENVIRONMENT_TYPE());

  return (
    <>
      <div className="main_section recommend">
        <div className="main_inner">
          <div className="con_top">
            <h3 className="con_top-title">{userInfo?.name}님을 위한 추천 콘텐츠</h3>
            <Link
              {...getGAClickProps('모두 보기', '추천 콘텐츠')}
              to="/recommend/list"
              className="con_top-link"
              onClick={e => {
                all_click_datalayer_push(e.currentTarget);
              }}
            >
              모두 보기
            </Link>
          </div>
        </div>
        <div className="main_row">
          {/* 화면 크기에 따라(모바일 화면) swiper로 카드 보여줌 */}
          {screenSize === 'sm' ? (
            <Swiper threshold={10} slidesPerView={1.4} className="card_row has-hover recommendSwiper">
              {props.mainRecommendList
                ?.flatMap(data => ({
                  ...data,
                  bestCommentContents: getCommentContentsByReplacedMentionTagToGoalBangYee(
                    data?.bestCommentContents || ''
                  ),
                }))
                ?.map(r => (
                  <SwiperSlide className="card" key={r.mediaId}>
                    <ContentCard
                      {...r}
                      currentDt={props.currentDt}
                      path={`${paths.getPath('videoDetail').replace(':mediaId', r.mediaId as string)}`}
                      isSwiperSlide={true}
                      key={r.mediaId}
                      GAObj={getContentsGAProps({
                        contentsType: 'MEDIA',
                        title: r.title as string,
                        tags: r.mediaTag as string,
                        category: r.cateNm as string,
                        dataContentBelong: '추천콘텐츠',
                        maker: r?.creatorNm as string,
                        time: r.videoTotalTime,
                        brightcoveId: r.videoId as string,
                        mediaContentsType: r.mediaContentsType as string,
                        dataContentUrl: `${paths.getPath('videoDetail').replace(':mediaId', r.mediaId as string)}`,
                        dataImgUrl: `${r?.thumbImageUri as string}`,
                      })}
                    />
                  </SwiperSlide>
                ))}
              {props.mainRecommendEvent ? (
                // 75주년 보드 이벤트 임시 덮어쓰기
                messageEventIds &&
                messageEventIds.eventIds.find(e => e.eventId === props.mainRecommendEvent?.eventId) ? (
                  <SwiperSlide className="card">
                    <Link
                      to={
                        messageEventIds?.eventIds.find(e => e.eventId === props.mainRecommendEvent?.eventId)?.linkUrl ||
                        '/event/list'
                      }
                    >
                      <div className="card_thumb">
                        <img
                          src={
                            imageUrl(props.mainRecommendEvent.thumbImageUri) ||
                            '/assets/images/illust/illust-img-event.png'
                          }
                          className="card_thumb_img"
                        />
                      </div>
                      <div className="card_con">
                        <div className="card_title has-dday">
                          {props.mainRecommendEvent.title}
                          {/* {props.mainRecommendEvent.eventStatus === 'ING' && (
                            <span className="card_title_dday">{`D-${ddayCalc(
                              props.mainRecommendEvent.eventEndDt,
                            )}`}</span>
                          )}
                          {props.mainRecommendEvent.eventStatus === 'END' && (
                            <span className="card_title_dday is-done">D-0</span>
                          )} */}
                        </div>
                        <div className="card_desc">&nbsp;</div>
                        <div className="card_sub">
                          {/* <div className="card_time">
                            {relativeTime(props.mainRecommendEvent.postDt, props.currentDt)}
                          </div> */}
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ) : (
                  <SwiperSlide className="card">
                    <Link
                      to={() => {
                        if (
                          props.mainRecommendEvent?.eventType === 'M_QUIZ' ||
                          props.mainRecommendEvent?.eventType === 'QUIZ_WINNER'
                        ) {
                          return paths
                            .getPath('eventQuizDetail')
                            .replace(':eventId', props.mainRecommendEvent?.eventId || '');
                        } else if (
                          props.mainRecommendEvent?.eventType === 'SURVEY' ||
                          props.mainRecommendEvent?.eventType === 'SIMPLE_SURVEY'
                        ) {
                          return paths
                            .getPath('eventSurveyDetail')
                            .replace(':eventId', props.mainRecommendEvent?.eventId || '');
                        }

                        return paths
                          .getPath('eventDetail')
                          .replace(':eventId', props.mainRecommendEvent?.eventId || '');
                      }}
                      {...getEventGAProps({
                        type: props.mainRecommendEvent.eventType,
                        url: window.location.hostname + props.mainRecommendEvent.linkUri,
                        title: props.mainRecommendEvent?.title,
                        dataContentBelong: '추천콘텐츠',
                        status: props.mainRecommendEvent.eventStatus,
                        dataImgUrl: props.mainRecommendEvent.thumbImageUri,
                      })}
                      onClick={e => {
                        all_click_datalayer_push(e.currentTarget);
                      }}
                    >
                      <EventCard {...props.mainRecommendEvent} mainCard currentDt={props.currentDt} />
                    </Link>
                  </SwiperSlide>
                )
              ) : (
                <></>
              )}
            </Swiper>
          ) : (
            // {/* 기본 카드 형태 */}
            <div className="card_row has-hover recommendSwiper">
              <div className="card_wrapper">
                {props.mainRecommendList?.map(r => (
                  <ContentCard
                    {...r}
                    currentDt={props.currentDt}
                    path={`${paths.getPath('videoDetail').replace(':mediaId', r.mediaId as string)}`}
                    isSwiperSlide={false}
                    key={r.mediaId}
                    GAObj={getContentsGAProps({
                      contentsType: 'MEDIA',
                      title: r.title as string,
                      tags: r.mediaTag as string,
                      category: r.cateNm as string,
                      dataContentBelong: '추천콘텐츠',
                      maker: r?.creatorNm as string,
                      time: r.videoTotalTime,
                      brightcoveId: r.videoId as string,
                      mediaContentsType: r.mediaContentsType as string,
                      dataContentUrl: `${paths.getPath('videoDetail').replace(':mediaId', r.mediaId as string)}`,
                      dataImgUrl: `${r.thumbImageUri as string}`,
                    })}
                  />
                ))}
                {props.mainRecommendEvent ? (
                  // 75주년 보드 이벤트 임시 덮어쓰기
                  messageEventIds &&
                  messageEventIds.eventIds.find(e => e.eventId === props.mainRecommendEvent?.eventId) ? (
                    <Link
                      to={
                        messageEventIds?.eventIds.find(e => e.eventId === props.mainRecommendEvent?.eventId)?.linkUrl ||
                        '/event/list'
                      }
                      className="card"
                    >
                      <div className="card_thumb">
                        <img
                          src={
                            imageUrl(props.mainRecommendEvent.thumbImageUri) ||
                            '/assets/images/illust/illust-img-event.png'
                          }
                          className="card_thumb_img"
                        />
                      </div>
                      <div className="card_con">
                        <div className="card_title has-dday">
                          {props.mainRecommendEvent.title}
                          {/* {props.mainRecommendEvent.eventStatus === 'ING' && (
                            <span className="card_title_dday">{`D-${ddayCalc(
                              props.mainRecommendEvent.eventEndDt,
                            )}`}</span>
                          )}
                          {props.mainRecommendEvent.eventStatus === 'END' && (
                            <span className="card_title_dday is-done">D-0</span>
                          )} */}
                        </div>
                        <div className="card_desc">&nbsp;</div>
                        <div className="card_sub">
                          {/* <div className="card_time">
                            {relativeTime(props.mainRecommendEvent.postDt, props.currentDt)}
                          </div> */}
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <Link
                      to={() => {
                        if (
                          props.mainRecommendEvent?.eventType === 'M_QUIZ' ||
                          props.mainRecommendEvent?.eventType === 'QUIZ_WINNER'
                        ) {
                          return paths
                            .getPath('eventQuizDetail')
                            .replace(':eventId', props.mainRecommendEvent?.eventId || '');
                        } else if (
                          props.mainRecommendEvent?.eventType === 'SURVEY' ||
                          props.mainRecommendEvent?.eventType === 'SIMPLE_SURVEY'
                        ) {
                          return paths
                            .getPath('eventSurveyDetail')
                            .replace(':eventId', props.mainRecommendEvent?.eventId || '');
                        }

                        return paths
                          .getPath('eventDetail')
                          .replace(':eventId', props.mainRecommendEvent?.eventId || '');
                      }}
                      className="card"
                      {...getEventGAProps({
                        type: props.mainRecommendEvent.eventType,
                        url: window.location.hostname + props.mainRecommendEvent.linkUri,
                        title: props.mainRecommendEvent?.title,
                        dataContentBelong: '추천콘텐츠',
                        status: props.mainRecommendEvent.eventStatus,
                        dataImgUrl: props.mainRecommendEvent.thumbImageUri,
                      })}
                      onClick={e => {
                        all_click_datalayer_push(e.currentTarget);
                      }}
                    >
                      <div className="card_item">
                        <EventCard {...props.mainRecommendEvent} mainCard currentDt={props.currentDt} />
                      </div>
                    </Link>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MainRecommend;
