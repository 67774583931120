import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import useMyInfo from './useMyInfo';

const useCommentList = (contentsId: string, contentsType: string) => {
  const { info } = useMyInfo();
  const { data, mutate } = useSWR<CommentListResDto>(info ? [endpoints.comment.comment, contentsId] : null, () =>
    fetcher.comment.getComment({ contentsId, contentsType }).then(res => {
      return res;
    })
  );

  return {
    data: data as CommentListResDto,
    resultCode: Number(data?.resultCode || 0),
    resultMessage: data?.resultMessage || '',
    mutate,
    addComment(addCmtReq: CommentAddReqDto) {
      fetcher.comment.postComment(addCmtReq).then(res => {
        if (res.resultCode === 200) mutate();
      });
    },
    async likeComment(commentId: string) {
      await fetcher.comment.postCommentLike({ commentId }).then(res => {
        if (res.resultCode === 200) mutate();
      });
    },
    modifyComment: async (params: CommentModifyReqDto) => {
      return fetcher.comment.putCommentModify(params).then(res => {
        if (res.resultCode === 200) mutate();
        return res;
      });
    },
    deleteComment: async (params: CommentDeleteReqDto) => {
      return fetcher.comment.deleteComment(params).then(res => {
        if (res.resultCode === 200) mutate();
        return res;
      });
    },
  };
};

export default useCommentList;
