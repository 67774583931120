import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import paths from 'commons/paths';
import { useMyPage } from 'hooks';
import { MyMentionsBoardTable } from '../../pages/MyPage/Community/MentionsBoard';
import { MyDepartmentBoardTable } from '../../pages/MyPage/Community/DepartmentBoard';

const BOARD_COUNT_TO_SHOW = 5;

const DepartmentBoardSection = () => {
  const { departmentBoards, departmentBoardsIsValidating, setPage } = useMyPage({});

  useEffect(() => {
    setPage('1');
  }, []);

  if (departmentBoardsIsValidating) return <></>;

  return (
    <div className="mypage_section">
      <div className="mypage_inner">
        <div className="mypage_top">
          <h3 className="mypage_top_title">작성한 게시글 - 회사 속 직무</h3>
          {(departmentBoards?.totalAmount as number) > BOARD_COUNT_TO_SHOW ? (
            <Link to={`${paths.getPath('myDepartmentBoard')}`} className="mypage_top_link">
              모두 보기
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="mypage_board list_table">
        <MyDepartmentBoardTable
          list={departmentBoards?.departmentBoardList || []}
          count={departmentBoards?.totalAmount as number}
          limit={BOARD_COUNT_TO_SHOW}
          ga_belong="마이페이지/작성한 게시글 - 회사 속 직무"
        />
      </div>
    </div>
  );
};

export default DepartmentBoardSection;
