import request from 'services/request';
import { endpoints } from 'services/endpoints';

export interface CommentRequest {
  contentsId: string;
  contentsType: string;
}

export interface CommentAddReq {
  contents: string;
  contentsId: string;
  contentsType: 'MEDIA' | 'BOARD' | 'EVENT' | 'STORY';
  depth: number;
  refCommentId?: string;
}

export interface CommentLikeReq {
  commentId: string;
}

export default {
  getComment: (params: CommentRequest): Promise<CommentListResDto> =>
    request({
      url: endpoints.comment.comment,
      method: 'get',
      params,
    }),
  postComment: (params: CommentAddReqDto): Promise<BaseResDto> =>
    request({
      url: endpoints.comment.comment,
      method: 'post',
      data: params,
    }),
  getReplyComment: (url: string): Promise<CommentReplyListResDto> =>
    request({
      url,
      method: 'get',
    }),
  postCommentLike: (params: CommentLikeReq): Promise<BaseResDto> =>
    request({
      url: endpoints.comment.like,
      method: 'post',
      params,
    }),
  putCommentModify: (params: CommentModifyReqDto): Promise<BaseResDto> =>
    request({
      url: endpoints.comment.putComment,
      method: 'put',
      data: params,
    }),
  deleteComment: (params: CommentDeleteReqDto): Promise<BaseResDto> =>
    request({
      url: endpoints.comment.deleteComment,
      method: 'delete',
      data: params,
    }),
};
