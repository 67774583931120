import React from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { parse, stringify } from 'query-string';
import paths from 'commons/paths';
import { BoardCard } from 'components/molecules';
import { ScreenSize } from '../MyPage';
import { all_click_datalayer_push, getGAClickProps } from '../../../utils/GAUtils';
import TopEatsBoardCard from '../../molecules/Community/TopEatsBoard/TopEatsBoardCard';

interface Props {
  list?: BoardCardResDto[];
  count?: number;
  /** 상단 메뉴 노출 */
  showTopTitle?: boolean;
  screenSize?: ScreenSize;
}

const SearchTopEatsBoard = ({ list = [], count = 0, screenSize = 'lg', showTopTitle = true }: Props) => {
  const history = useHistory();
  const keyword = parse(location.search).keyword as string;

  return (
    <>
      <div className="search_inner">
        <div className="search_top">
          {showTopTitle && (
            <h3 className="search_top_title">
              회사 옆 맛집
              <span className="is-num" {...{ id: 'blogSearchNum', datasearchnum: count }}>
                ({count}개)
              </span>
            </h3>
          )}
          {count > 4 ? (
            <a
              {...getGAClickProps('모두 보기', '회사 옆 맛집')}
              href="#seeAll"
              className="search_top_link"
              onClick={e => {
                e.preventDefault();
                all_click_datalayer_push(e.currentTarget);
                history.push({
                  pathname: paths.getPath('topEatsBoardList'),
                  search: stringify({ keyword: keyword }),
                });
              }}
            >
              모두 보기
            </a>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="search_row">
        {screenSize === 'lg' ? (
          <div className="boardSwiper">
            <div className="board_list grid" style={{ display: 'flex' }}>
              {list?.map(item => (
                <div className="board_item grid-item" key={item.boardId}>
                  <TopEatsBoardCard {...item} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Swiper
            threshold={70}
            className="boardSwiper"
            slidesPerView={1.4}
            breakpoints={{ 768: { slidesPerView: 3.1 } }}
          >
            {list?.map(item => (
              <SwiperSlide className="board_item grid-item" key={item.boardId}>
                <TopEatsBoardCard {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </>
  );
};

export default SearchTopEatsBoard;
