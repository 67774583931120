import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { stringify } from 'query-string';
import ContentCard, { ContentCardProps } from 'components/molecules/ContentCard';
import Paths from 'commons/paths';
import { all_click_datalayer_push, getContentsGAProps, getGAClickProps } from 'utils/GAUtils';

interface MainSnsProps {
  snsList: Array<MediaCardResDto>;
  currentDt: string;
}

const MainSns = (props: MainSnsProps) => {
  const { snsList } = props;
  const [screenSize, setScreenSize] = useState('lg');
  const history = useHistory();

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1023) setScreenSize('lg');
    else if (width > 767) setScreenSize('md');
    else setScreenSize('sm');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderingCard = (s: ContentCardProps, className: string, idx: number) => {
    return (
      <ContentCard
        {...s}
        path={Paths.getPath('videoDetail').replace(':mediaId', s.mediaId as string)}
        isSwiperSlide={className !== 'card'}
        key={s.mediaId}
        GAObj={getContentsGAProps({
          contentsType: 'MEDIA',
          title: s.title as string,
          tags: s.mediaTag as string,
          category: s.cateNm as string,
          dataContentBelong: 'LG 계열사 콘텐츠',
          maker: s.creatorNm as string,
          time: s.videoTotalTime,
          brightcoveId: s.videoId as string,
          mediaContentsType: s.mediaContentsType as string,
          dataContentUrl: `${Paths.getPath('videoDetail').replace(':mediaId', s.mediaId as string)}`,
          dataImgUrl: `${s?.thumbImageUri as string}`,
        })}
      />
    );
  };

  return (
    <>
      <div className="main_section sns">
        <div className="main_inner">
          <div className="con_top">
            <h3 className="con_top-title">LG 계열사 콘텐츠</h3>
            <a
              href="#"
              className="con_top-link"
              {...getGAClickProps('모두 보기', 'LG 계열사 콘텐츠')}
              onClick={e => {
                e.preventDefault();
                history.push({
                  pathname: Paths.getPath('videoList'),
                  search: stringify({ keyword: 'LG 계열사 콘텐츠' }),
                });
                all_click_datalayer_push(e.currentTarget);
              }}
            >
              모두 보기
            </a>
          </div>
        </div>
        <div className="main_row">
          {screenSize === 'lg' ? (
            <div className="card_row is-small has-hover snsSwiper">
              {snsList.map((s, idx) => renderingCard({ ...s, currentDt: props.currentDt }, 'card', idx))}
            </div>
          ) : (
            <Swiper
              className="card_row is-small snsSwiper"
              slidesPerView={1.4}
              threshold={10}
              breakpoints={{ 768: { slidesPerView: 3.1 } }}
            >
              {snsList.map((s, idx) => (
                <SwiperSlide className="card" key={idx}>
                  {renderingCard({ ...s, currentDt: props.currentDt }, '', idx)}
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </>
  );
};

export default MainSns;
