import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useCommentReply } from 'hooks';
import { endpoints, fetcher } from 'services';
import queryString from 'query-string';
import dompurify from 'dompurify';
import MyInfoContext from 'contexts/MyInfoContext';

import SimpleComment from './SimpleComment';
import ProfileImage from 'components/atoms/ProfileImage';
import { relativeTime } from 'utils/utils';
import ProfilePopup from 'components/organisms/Popup/ProfilePopup';
import { all_click_datalayer_push, getContentsButtonGAProps } from 'utils/GAUtils';
import { useHistory } from 'react-router-dom';
import EmojiComment from '../EmojiComment';
import MentionAddComment from '../MentionComment/MentionAddComment';
import MentionModifyComment from '../MentionComment/MentionModifyComment';
import { getCommentContentsByReplacedMentionTagToHTMLTag } from '../../../utils/mentionUtils';
import { CommentPostType } from '../../../types/enum/CommentPostType';
import { getDangerouslySetInnerHTMLValueBySanitizedHtml } from '../../../utils/LGLifeUtils';

export interface CommentItemProps extends CommentResDto {
  closeCmt?: () => void;
  isBest?: boolean;
  like?: (commentId: string) => void;
  update?: () => void;
  contentsId: string;
  commentPostType: CommentPostType;
  magazineType?: 'CARD' | 'CARD_COMICS' | 'WEEK_NEWSLETTER';
  tags: string;
  title: string;
  category: string;
  contentCreatorNm: string;
  time?: number;
  brightcoveId?: string;
  currentDt: string;
  modify?: (params: CommentModifyReqDto) => void;
  delete?: (params: CommentDeleteReqDto) => void;
  mutateCmt?: () => {};
  eventType?: 'EDITOR' | 'SURVEY' | 'M_QUIZ' | 'QUIZ_WINNER' | 'SIMPLE_SURVEY';
  eventStatus?: 'ING' | 'END' | 'ALL';
  isfixed?: boolean;
  mediaContentType?: string;
  isNews?: boolean;
  commentSearchMailWidth: () => void;
}

const CommentItem = (props: CommentItemProps) => {
  const history = useHistory();
  const { addReply, likeReply, modifyReply, deleteReply } = useCommentReply(props.commentId);
  const info = useContext(MyInfoContext);
  const [userInfo, setUserInfo] = useState<any>();
  const [replyList, setReplyList] = useState([] as CommentResDto[]);
  const [profilePopup, setProfilePopup] = useState(false);
  const [mentionProfilePopup, setMentionProfilePopup] = useState(false);
  const [cmtDeletePopup, setCmtDeletePopup] = useState(false);
  const [modCancelPopup, setModCancelPopup] = useState(false);
  const [addCancelPopup, setAddCancelPopup] = useState(false);
  const [trigger, setTrigger] = useState('');
  const isMobile = useMemo(() => window.innerWidth < 1024, [window.innerWidth]);
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const [pushCommentId, setPushCommentId] = useState<any>('');
  /** 답글 Text Area 영역 열려 있는지 여부 */
  const [isReplyTextAreaOpen, setIsTextAreaOpen] = useState(false);
  const [comment, setComment] = useState<string>('');
  const [mentionUserId, setMentionUserId] = useState('');

  const commentRef = useRef<HTMLDivElement>(null);
  const cmtFormRef = useRef<HTMLDivElement>(null);
  const cmtReplyRef = useRef<HTMLDivElement>(null);
  const cmtTextareaRef = useRef<HTMLTextAreaElement>(null);

  const pcCmtDivRef = useRef<HTMLDivElement>(null);
  const pcCmtInputRef = useRef<HTMLDivElement>(null);

  const mobCmtDivRef = useRef<HTMLDivElement>(null);
  const mobCmtInputRef = useRef<HTMLDivElement>(null);

  const mobCmtFixDivRef = useRef<HTMLDivElement>(null);
  const mobCmtFixInputRef = useRef<HTMLDivElement>(null);

  // PC 수정 영역
  const modifyCmtDivRef = useRef<HTMLDivElement>(null);
  const modifyCmtInputRef = useRef<HTMLDivElement>(null);

  const mobModifyCmtDivRef = useRef<HTMLDivElement>(null);
  const mobModifyCmtInputRef = useRef<HTMLDivElement>(null);

  const cmtItemWrapRef = useRef<HTMLDivElement>(null);
  const mobModifyRef = useRef<HTMLDivElement>(null);

  const cmtItemDescRef = useRef<HTMLDivElement>(null);

  /** 댓글 id 가 query string 값에 포함 되어 있는지 여부 */
  const hasCommentId = useMemo(() => {
    const query = queryString.parse(location.search);
    const commentId = query.commentId;
    setPushCommentId(commentId);
    return !!commentId && query.commentId === props.commentId;
  }, [history.location.pathname, history.location.state, location.search]);

  /** 대댓글 id 가 query string 값에 포함 되어 있는지 여부 */
  const hasReplyCommentId = useMemo(() => {
    const query = queryString.parse(location.search);
    const replyCommentId = query.replyId;
    if (!hasCommentId) return false;
    return !!replyCommentId;
  }, [history.location.pathname, history.location.state, location.search, hasCommentId]);

  const time = useMemo(() => relativeTime(props.createdDt, props.currentDt), [props.createdDt, props.currentDt]);

  useEffect(() => {
    if (info) setUserInfo(info);
  }, [info]);

  useEffect(() => {
    commentRef.current?.classList.remove('is-appended');
    setIsReplyOpen(false);
    setIsTextAreaOpen(false);

    if (hasCommentId && commentRef.current?.id === pushCommentId) {
      if (hasReplyCommentId) {
        commentRef.current?.classList.add('is-appended');
        setIsReplyOpen(true);
        fetcher.comment.getReplyComment(`${endpoints.comment.comment}/${props.commentId}`).then(res => {
          if (res.resultCode === 200) setReplyList(res.commentReplyList as CommentResDto[]);
        });

        setTimeout(() => {
          commentRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }, 1000);
      } else {
        setTimeout(() => {
          commentRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }, 1000);
      }
    }
  }, [
    history.location.pathname,
    history.location.state,
    props.commentId,
    location.search,
    hasCommentId,
    hasReplyCommentId,
  ]);

  useEffect(() => {
    if (!props.contents) return;
    setComment(getCommentContentsByReplacedMentionTagToHTMLTag(props.contents));
  }, [props.contents]);

  useEffect(() => {
    if (cmtItemDescRef.current) {
      cmtItemDescRef.current.querySelectorAll('.mention_tag').forEach(tag => {
        tag.addEventListener('click', () => {
          setMentionUserId(tag.getAttribute('data-mention-user-id') as string);
          setMentionProfilePopup(true);
        });
      });
    }
  }, [comment]);

  /** 답글 영역 열기 */
  const openReplyCommentArea = commentId => {
    if (!commentId) return;

    commentRef.current?.classList.add('is-appended');
    setIsReplyOpen(true);
    fetcher.comment.getReplyComment(`${endpoints.comment.comment}/${commentId}`).then(res => {
      if (res.resultCode === 200) {
        setReplyList(res.commentReplyList as CommentResDto[]);
      }
    });
  };

  /** 답글 영역 닫기 */
  const closeReplyCommentArea = () => {
    commentRef.current?.classList.remove('is-appended');
    setIsReplyOpen(false);
  };

  const controlCmtForm = e => {
    setIsTextAreaOpen(!isReplyTextAreaOpen);

    e.preventDefault();
    cmtFormRef.current?.classList.toggle('is-active');
    setTrigger(JSON.stringify(cmtFormRef.current?.classList));
    if (window.innerWidth < 1024) {
      commentRef.current?.classList.toggle('is-appended');
      setIsReplyOpen(!isReplyOpen);
      cmtReplyRef.current?.classList.add('is-reply-active');
      document.querySelector('nav')?.style.setProperty('display', '');
      fetcher.comment.getReplyComment(`${endpoints.comment.comment}/${props.commentId}`).then(res => {
        if (res.resultCode === 200) {
          setReplyList(res.commentReplyList as CommentResDto[]);
        }
      });
      mobCmtDivRef.current?.focus();
    } else {
      pcCmtDivRef.current?.focus();
    }
  };

  const controlReply = e => {
    e.preventDefault();
    if (commentRef.current?.classList.contains('is-appended')) {
      closeReplyCommentArea();
    } else {
      openReplyCommentArea(props.commentId);
    }
  };

  const commentDivFocus = (divRef: any) => {
    const sel = window.getSelection();
    const innerDiv = divRef.current;

    if (innerDiv) {
      if (innerDiv.lastChild) {
        sel?.collapse(innerDiv.lastChild, innerDiv?.lastChild?.textContent?.length || 0);
      } else {
        innerDiv.innerHTML = '';
        sel?.collapse(innerDiv, 0);
      }
    }

    divRef.current?.focus();
  };

  return (
    <>
      <div
        className={classNames('comment_item', {
          'is-best': props.isBest,
          'is-fix': props.fixedYn === 'Y' && props.isfixed,
        })}
        ref={commentRef}
        id={props.commentId}
      >
        {(props.delYn === 'N' || (props.delUser === 'ADMIN' && props.creatorId === info.userId)) && (
          <>
            {props.fixedYn === 'Y' && props.isfixed && <div className="comment_label">고정</div>}
            {props.isBest && <div className="comment_label">베스트 댓글</div>}
            <div
              className={classNames('comment_item_wrap', { 'is-mine': props.creatorId === userInfo?.userId })}
              ref={cmtItemWrapRef}
            >
              <div className="comment_item_profile">
                <ProfileImage
                  isAdmin={props.creatorAdminYn === 'Y' || props.adminRegYn === 'Y'}
                  name={props.creatorNm as string}
                  score={props.creatorActScore as number}
                  image={props.creatorProfileUri}
                />
              </div>
              <div className="comment_item_con">
                <div className="comment_item_name">
                  {/* mobile 여부 판단 후 노출 여부 결정 */}
                  {props.mobileRegYn === 'Y' && (
                    <span className="comment_item_mob">
                      <img src="/assets/images/icon/ico-mob-32.png" alt="모바일 아이콘" />
                    </span>
                  )}
                  <a
                    href="#"
                    className="comment_item_name_link"
                    onClick={e => {
                      e.preventDefault();
                      if (props.creatorAdminYn === 'N' && props.creatorRetireYn === 'N' && props.adminRegYn === 'N')
                        setProfilePopup(true);
                    }}
                  >
                    {props.creatorAdminYn === 'Y' || props.adminRegYn === 'Y' ? (
                      '관리자'
                    ) : (
                      <>
                        {props.creatorNm}
                        {props.creatorRetireYn === 'N' && (
                          <>
                            <span style={{ paddingLeft: '4px', color: 'lightgray' }}>|</span>
                            <span className="comment_item_company" style={{ paddingLeft: '4px' }}>
                              {props.creatorGroupNm}
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </a>
                  <span className="comment_item_time">
                    {time}
                    {props.modifiedDt !== props.createdDt ? ' (수정됨)' : ''}
                  </span>
                </div>
                <div
                  className="comment_item_desc"
                  ref={cmtItemDescRef}
                  dangerouslySetInnerHTML={{
                    __html: getDangerouslySetInnerHTMLValueBySanitizedHtml(comment),
                  }}
                ></div>
                {
                  <div className="comment_item_sub">
                    <a
                      className={classNames('comment_item_likes', { 'is-active': props.likeStatus === 'Y' })}
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        props.like && props.like(props.commentId as string);
                        all_click_datalayer_push(e.currentTarget);
                      }}
                      {...getContentsButtonGAProps({
                        buttonType: '좋아요',
                        contentType: props.isNews ? 'NEWS' : props.magazineType ? 'CARD' : props.commentPostType,
                        magazineType: props.magazineType,
                        tags: props.tags as string,
                        title: props.title as string,
                        time: props.time ? props.time : -1,
                        dataContentCategory: props.category as string,
                        creatorNm: props.contentCreatorNm as string,
                        brightcoveId: props.brightcoveId as string,
                        mediaContentsType: props.mediaContentType as string,
                      })}
                    >
                      {props.likeCnt}
                    </a>

                    {props.fixedYn !== 'Y' && (
                      <a href="#" className="comment_item_reply" onClick={controlCmtForm}>
                        답글
                      </a>
                    )}
                  </div>
                }

                <div className="comment_form is-pc" ref={cmtFormRef}>
                  <div className="comment_profile">
                    <ProfileImage
                      name={userInfo?.name as string}
                      score={userInfo?.actScore as number}
                      image={userInfo?.profileImage}
                      isAdmin={userInfo?.isAdmin === 'Y'}
                      cmtFormOpen={trigger}
                    />
                  </div>
                  <div className="comment_input" ref={pcCmtInputRef}>
                    <MentionAddComment
                      {...props}
                      isReply={true}
                      cmtDivRef={pcCmtDivRef}
                      cmtInputRef={pcCmtInputRef}
                      setReplyList={setReplyList}
                      update={props.update}
                      openReplyCommentArea={() => openReplyCommentArea(props.commentId)}
                    />
                  </div>
                </div>
                {isReplyTextAreaOpen && (
                  <div className="comment_emoji is-pc">
                    {/* emoji */}
                    <EmojiComment
                      clickEmoji={emoji => {
                        if (pcCmtDivRef.current) {
                          pcCmtDivRef.current.innerHTML = pcCmtDivRef.current?.innerHTML + emoji;
                          commentDivFocus(pcCmtDivRef);
                        }
                      }}
                      {...props}
                    />
                  </div>
                )}
                {(props.replyCnt as number) > 0 && (
                  <a className="comment_reply_btn" href="#" onClick={controlReply}>
                    답글<span className="comment_reply_num">{props.replyCnt}</span>개
                    <span className="comment_reply_more">보기</span>
                  </a>
                )}
                {/* 댓글 수정/삭제 버튼 */}
                <button className="comment_dot" onClick={() => cmtItemWrapRef.current?.classList.toggle('is-active')}>
                  <img src="/assets/images/icon/ico-comment-dot.png" alt="comment menu" className="comment_dot_img" />
                </button>
                <div className="comment_dot_menu" style={{ zIndex: 80 }}>
                  <div className="comment_dot_list" style={{ zIndex: 80 }}>
                    <div className="comment_dot_item">
                      <a
                        className="comment_dot_modi"
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          cmtItemWrapRef.current?.classList.remove('is-active');
                          cmtItemWrapRef.current?.classList.add('is-modify');
                          if (isMobile) {
                            mobModifyRef.current?.classList.add('is-active');
                            document.querySelector('nav')?.style.setProperty('display', 'none');
                            mobModifyCmtDivRef.current?.focus();
                          } else {
                            modifyCmtDivRef.current?.focus();
                          }
                        }}
                      >
                        수정
                      </a>
                    </div>
                    <div className="comment_dot_item">
                      <a
                        className="comment_dot_del"
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setCmtDeletePopup(true);
                        }}
                      >
                        삭제
                      </a>
                    </div>
                    <div className="comment_dot_cancel is-mobile">
                      <button className="popup_confirm">취소</button>
                    </div>
                    <button
                      className="comment_dot_close is-mobile"
                      onClick={() => {
                        cmtItemWrapRef.current?.classList.remove('is-active');
                      }}
                    >
                      <img src="/assets/images/icon/ico-action-24-close.svg" alt="close" />
                    </button>
                  </div>
                </div>
                {/* <!-- 댓글 수정 인풋 --> */}
                <div className="comment_modify">
                  <div className="comment_input">
                    <MentionModifyComment
                      {...props}
                      contents={props.contents || ''}
                      isReply={false}
                      isFocusStart={true}
                      cmtDivRef={modifyCmtDivRef}
                      cmtInputRef={modifyCmtInputRef}
                      cmtItemWrapRef={cmtItemWrapRef}
                    />
                  </div>
                </div>
                {/* <!-- 댓글 수정 인풋(모바일) --> */}
                {/* 댓글 리스트 댓글 수정 (모바일)*/}
                <div className="comment_modify_mo">
                  <div className="comment_fixed" ref={mobModifyRef}>
                    <div className="comment_fixed_inner">
                      <div className="comment_profile">
                        <ProfileImage
                          name={userInfo?.name || ''}
                          score={userInfo?.actScore as number}
                          image={userInfo?.profileImage}
                          isAdmin={userInfo?.isAdmin === 'Y'}
                        />
                      </div>
                      <MentionModifyComment
                        {...props}
                        contents={props.contents || ''}
                        isReply={false}
                        isFocusStart={true}
                        cmtDivRef={mobModifyCmtDivRef}
                        cmtInputRef={mobModifyCmtInputRef}
                        cmtItemWrapRef={cmtItemWrapRef}
                        isMobile={true}
                        mobModifyRef={mobModifyRef}
                        setModCancelPopup={setModCancelPopup}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- 답글영역 --> */}
            {isReplyOpen && (
              <div className="comment_reply" ref={cmtReplyRef}>
                <div className="comment_reply_inner">
                  <div className="comment_title_mob is-mobile">
                    {/* 답글 끄고 댓글 목록으로 */}
                    <button className="comment_prev" onClick={controlReply}>
                      <img src="/assets/images/icon/ico-action-16.svg" alt="close" />
                    </button>
                    <span className="comment_title">답글</span>
                    {/* <!-- 답글 닫기 버튼에는 is-reply 클래스 추가 --> */}
                    {/* 댓글 다 끄기 */}
                    <button
                      className="comment_close is-reply"
                      onClick={e => {
                        // 댓글의 답글을 보고 있던 상태를 유지할지 말지..?
                        controlReply(e);
                        if (props.closeCmt) props.closeCmt();
                      }}
                    >
                      <img src="/assets/images/icon/ico-action-24-close.svg" alt="close" />
                    </button>
                  </div>
                  <div className="comment_reply_wrap">
                    {/* 모바일 버전 - 답글 보기 누른 경우(원 댓글과 그 답글만 화면에 보인다) */}
                    <div className="comment_reply_original is-mobile">
                      <SimpleComment
                        {...props}
                        modify={modifyReply}
                        delete={deleteReply}
                        mutateCmt={props.mutateCmt}
                        setMentionUserId={setMentionUserId}
                        setMentionProfilePopup={setMentionProfilePopup}
                      />
                    </div>
                    <div className="comment_reply_form is-mobile">
                      <div className="comment_profile">
                        <ProfileImage
                          name={userInfo?.name || ''}
                          score={userInfo?.actScore as number}
                          image={userInfo?.profileImage}
                          isAdmin={userInfo?.isAdmin === 'Y'}
                        />
                      </div>
                      <div className="comment_input">
                        <a
                          className="comment_placeholder is-mobile"
                          onClick={() => {
                            cmtReplyRef.current?.classList.add('is-reply-active');
                            document.querySelector('nav')?.style.setProperty('display', 'none');
                            cmtTextareaRef.current?.focus();
                          }}
                        >
                          '@'를 입력해 보세요. 동료 임직원을 소환할 수 있어요!
                        </a>
                        <MentionAddComment
                          {...props}
                          isReply={true}
                          cmtDivRef={mobCmtDivRef}
                          cmtInputRef={mobCmtInputRef}
                          cmtReplyRef={cmtReplyRef}
                          isMobileCmtInput={true}
                          isMobile={true}
                          setAddCancelPopup={setAddCancelPopup}
                        />
                      </div>
                      {/* 모바일 답글 */}
                      <div className="comment_fixed">
                        <div className="comment_fixed_inner">
                          <div className="comment_profile">
                            {/* <img src="/assets/images/icon/ico-profile.png" alt="" /> */}
                            <ProfileImage
                              name={userInfo?.name || ''}
                              score={userInfo?.actScore as number}
                              image={userInfo?.profileImage}
                              isAdmin={userInfo?.isAdmin === 'Y'}
                            />
                          </div>
                          <MentionAddComment
                            {...props}
                            isReply={true}
                            commentId={props.commentId}
                            setReplyList={setReplyList}
                            cmtDivRef={mobCmtFixDivRef}
                            cmtInputRef={mobCmtFixInputRef}
                            isMobileCmtInput={true}
                            isMobile={true}
                            setAddCancelPopup={setAddCancelPopup}
                            cmtReplyRef={cmtReplyRef}
                            mobModifyRef={mobModifyRef}
                          />
                        </div>
                      </div>
                    </div>
                    {/* 답글 리스트 */}
                    {replyList &&
                      replyList.map((rcmt, idx) => (
                        <SimpleComment
                          {...rcmt}
                          likeReply={likeReply}
                          setReply={() => {
                            fetcher.comment
                              .getReplyComment(`${endpoints.comment.comment}/${props.commentId}`)
                              .then(res => {
                                if (res.resultCode === 200) setReplyList(res.commentReplyList as CommentResDto[]);
                              });
                          }}
                          contentsId={props.contentsId}
                          commentPostType={props.commentPostType}
                          tags={props.tags}
                          title={props.title}
                          category={props.category}
                          contentCreatorNm={props.contentCreatorNm}
                          time={props.time}
                          brightcoveId={props.brightcoveId}
                          currentDt={props.currentDt}
                          modify={modifyReply}
                          delete={deleteReply}
                          commentSearchMailWidth={props.commentSearchMailWidth}
                          setMentionUserId={setMentionUserId}
                          setMentionProfilePopup={setMentionProfilePopup}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {props.delYn === 'Y' && (props.delUser === 'SELF' || props.creatorId !== info.userId) && (
          <div className="comment_delete">{props.contents}</div>
        )}
      </div>
      {profilePopup && <ProfilePopup userId={props.creatorId as string} close={() => setProfilePopup(false)} />}
      {mentionProfilePopup && mentionUserId && (
        <ProfilePopup userId={mentionUserId} close={() => setMentionProfilePopup(false)} />
      )}
      {cmtDeletePopup && (
        <div className={classNames('confirm_popup', 'comment_del_popup', { 'is-active': cmtDeletePopup })}>
          <div className="popup_dim"></div>
          <div className="popup_inner">
            <p className="confirm_popup_text">해당 댓글을 삭제 하겠습니까?</p>
            {/* <p className="confirm_popup_text">
            해당 답글을 삭제 하겠습니까?
          </p>  */}
            <ul className="confirm_button_list">
              <li className="confirm_button_item">
                <button
                  type="button"
                  className="confirm_popup_button is-dim popup_button_cancel"
                  onClick={() => setCmtDeletePopup(false)}
                >
                  취소
                </button>
              </li>
              <li className="confirm_button_item">
                <button
                  type="button"
                  className="confirm_popup_button popup_button_confirm"
                  onClick={() => {
                    props.delete &&
                      props.delete({
                        commentId: props.commentId as string,
                        contentsId: props.contentsId,
                        contentsType: props.commentPostType,
                      });
                    setCmtDeletePopup(false);
                  }}
                >
                  삭제
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
      {modCancelPopup && (
        <div className={classNames('confirm_popup', 'comment_modi_popup', { 'is-active': modCancelPopup })}>
          <div className="popup_dim"></div>
          <div className="popup_inner">
            <p className="confirm_popup_text">수정사항을 삭제하시겠습니까?</p>
            <ul className="confirm_button_list">
              <li className="confirm_button_item">
                <button
                  type="button"
                  className="confirm_popup_button popup_button_cancel"
                  onClick={() => {
                    setModCancelPopup(false);
                    cmtItemWrapRef.current?.focus();
                  }}
                >
                  계속 작성
                </button>
              </li>
              <li className="confirm_button_item">
                <button
                  type="button"
                  className="confirm_popup_button popup_button_confirm"
                  onClick={() => {
                    setModCancelPopup(false);
                    cmtItemWrapRef.current?.classList.remove('is-modify');
                    mobModifyRef.current?.classList.remove('is-active');
                    document.querySelector('nav')?.style.setProperty('display', '');
                  }}
                >
                  삭제
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
      {addCancelPopup && (
        <div className={classNames('confirm_popup', 'comment_modi_popup', { 'is-active': addCancelPopup })}>
          <div className="popup_dim"></div>
          <div className="popup_inner">
            <p className="confirm_popup_text">댓글 작성을 취소하시겠습니까?</p>
            <ul className="confirm_button_list">
              <li className="confirm_button_item">
                <button
                  type="button"
                  className="confirm_popup_button popup_button_cancel"
                  onClick={() => {
                    setAddCancelPopup(false);
                    cmtTextareaRef.current?.focus();
                  }}
                >
                  계속 작성
                </button>
              </li>
              <li className="confirm_button_item">
                <button
                  type="button"
                  className="confirm_popup_button popup_button_confirm"
                  onClick={() => {
                    setAddCancelPopup(false);
                    cmtReplyRef.current?.classList.remove('is-reply-active');
                    mobCmtInputRef.current?.classList.remove('is-active');
                  }}
                >
                  취소
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(CommentItem, (prev, next) => {
  return JSON.stringify(prev) === JSON.stringify(next);
});
